import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { TabContent, TabPane, UncontrolledTooltip } from 'reactstrap';
import Identity from '../../helpers/Identity';
import DataSource from '../../helpers/DataSource';
import { motion } from "framer-motion";
import moment from 'moment';

export default function AsideSecondary(props) {

    const [activeClasses, setActiveClasses] = useState([false, false, false, false, false])

    const toggleNavbar = (e, index) => {
        const temp = [...activeClasses.slice(0, index), !activeClasses[index], activeClasses.slice(index + 1)].flat();
        setActiveClasses(temp);
    }

    const [wh, setWh] = useState([]);

    useEffect(() => {
        DataSource(`odata/Stores?$orderby=Id%20asc`).then(res => {
            setWh(res.data.value);
        });
    }, []);

    const setStoreId = (value) => {
        localStorage.setItem("StoreId", value.Id);
        localStorage.setItem("StoreName", value.Name);
        localStorage.setItem("StoreCreated", value.CreatedDate);
        localStorage.setItem("StoreExpiry", value.ExpiryDate);
        localStorage.setItem("FirstStore", value.FirstStore);
        window.location.href = '/';
    }

    return (
        <>
            <div className='aside-secondary d-flex flex-row-fluid'>
                <div className="aside-workspace mt-3 p-5">
                    <div className="d-flex h-100 flex-column">
                        <div className="border border-gray-300 bg-gray-100 rounded-3 p-3 mb-3 text-center">
                            <h5 className="text-gray-800 fw-bold mb-0">
                                {
                                    localStorage.getItem("FirstStore") === 'true' &&
                                    <svg className='mx-1' width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.0079 2.6L15.7079 7.2L21.0079 8.4C21.9079 8.6 22.3079 9.7 21.7079 10.4L18.1079 14.4L18.6079 19.8C18.7079 20.7 17.7079 21.4 16.9079 21L12.0079 18.8L7.10785 21C6.20785 21.4 5.30786 20.7 5.40786 19.8L5.90786 14.4L2.30785 10.4C1.70785 9.7 2.00786 8.6 3.00786 8.4L8.30785 7.2L11.0079 2.6C11.3079 1.8 12.5079 1.8 13.0079 2.6Z" fill="currentColor" />
                                    </svg>
                                }
                                {localStorage.getItem("StoreName")}
                            </h5>
                        </div>
                        <div className='flex-column-fluid hover-scroll-y' style={{ height: '430px', padding: '0px .25rem 0 .25rem' }}>
                            <TabContent activeTab={props.currentTab}>
                                <TabPane tabId="0">
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={props.currentTab === '1' ? { opacity: 1 } : { opacity: 0 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.8 }}>

                                    </motion.div>
                                </TabPane>
                                <TabPane tabId="1">
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={props.currentTab === '1' ? { opacity: 1 } : { opacity: 0 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.8 }}>
                                        <div className='menu menu-column menu-sub-indention menu-rounded menu-active-bg menu-title-gray-800 menu-icon-gray-800 menu-state-primary menu-arrow-gray-800 fw-bold fs-6 my-5 my-lg-0'>
                                            <div className='menu-item'>
                                                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Danh mục</span>
                                            </div>
                                            <div className="menu-item">
                                                <NavLink className="menu-link" to="/">
                                                    <span className="menu-icon">
                                                        <span className="svg-icon svg-icon-2">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="currentColor" />
                                                                <path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="currentColor" />
                                                            </svg>
                                                        </span>
                                                    </span>
                                                    <span className="menu-title">Tổng quan</span>
                                                </NavLink>
                                            </div>

                                            <Identity permissions="Staff.Read">
                                                <div className="menu-item">
                                                    <NavLink className="menu-link" to="/staff" tag={NavLink}>
                                                        <span className="menu-icon">
                                                            <span className="svg-icon svg-icon-2">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z" fill="currentColor" />
                                                                    <rect opacity="0.3" x="14" y="4" width="4" height="4" rx="2" fill="currentColor" />
                                                                    <path d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z" fill="currentColor" />
                                                                    <rect opacity="0.3" x="6" y="5" width="6" height="6" rx="3" fill="currentColor" />
                                                                </svg>
                                                            </span>
                                                        </span>
                                                        <span className="menu-title">Nhân viên</span>
                                                    </NavLink>
                                                </div>
                                            </Identity>

                                            <Identity permissions="Group.Read">
                                                <div className="menu-item">
                                                    <NavLink className="menu-link" to="/groups" tag={NavLink}>
                                                        <span className="menu-icon">
                                                            <span className="svg-icon svg-icon-2">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11 2.375L2 9.575V20.575C2 21.175 2.4 21.575 3 21.575H9C9.6 21.575 10 21.175 10 20.575V14.575C10 13.975 10.4 13.575 11 13.575H13C13.6 13.575 14 13.975 14 14.575V20.575C14 21.175 14.4 21.575 15 21.575H21C21.6 21.575 22 21.175 22 20.575V9.575L13 2.375C12.4 1.875 11.6 1.875 11 2.375Z" fill="currentColor" />
                                                                </svg>
                                                            </span>
                                                        </span>
                                                        <span className="menu-title">Nhà nhỏ</span>
                                                    </NavLink>
                                                </div>
                                            </Identity>

                                            <span className={activeClasses[4] ? "show menu-item menu-accordion mb-1" : "menu-item menu-accordion mb-1"}>
                                                <span className="menu-link">
                                                    <span className="menu-icon">
                                                        <span className="svg-icon svg-icon-2">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor" />
                                                                <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor" />
                                                            </svg>
                                                        </span>
                                                    </span>
                                                    <span className="menu-title" onClick={(e) => toggleNavbar(e, 4)}>Hệ thống</span>
                                                    <span className="menu-arrow"></span>
                                                </span>

                                                <div className='menu-sub menu-sub-accordion'>
                                                    <motion.div
                                                        initial={{ opacity: 0, y: -10 }}
                                                        animate={activeClasses[4] === true ? { opacity: 1, y: 0 } : { opacity: 0 }}
                                                        transition={{ duration: 0.3 }}>
                                                        <Identity permissions="Setting">
                                                            <div className="menu-item">
                                                                <NavLink className="menu-link" to="/settings">
                                                                    <span className="menu-bullet">
                                                                        <span className="bullet bullet-dot"></span>
                                                                    </span>
                                                                    <span className="menu-title">Thiết lập chung</span>
                                                                </NavLink>
                                                            </div>
                                                        </Identity>
                                                        <Identity permissions="Role.Read">
                                                            <div className="menu-item">
                                                                <NavLink className="menu-link" to="/role">
                                                                    <span className="menu-bullet">
                                                                        <span className="bullet bullet-dot"></span>
                                                                    </span>
                                                                    <span className="menu-title">Quản lý role</span>
                                                                </NavLink>
                                                            </div>
                                                        </Identity>
                                                        <div className="menu-item">
                                                            <NavLink className="menu-link" to="/history">
                                                                <span className="menu-bullet">
                                                                    <span className="bullet bullet-dot"></span>
                                                                </span>
                                                                <span className="menu-title">Lịch sử thao tác</span>
                                                            </NavLink>
                                                        </div>
                                                    </motion.div>
                                                </div>
                                            </span>
                                        </div>
                                    </motion.div>
                                </TabPane>
                                <TabPane tabId="3">
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={props.currentTab === '3' ? { opacity: 1 } : { opacity: 0 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.8 }}>
                                        <div className='menu menu-column menu-sub-indention menu-rounded menu-active-bg menu-title-gray-800 menu-icon-gray-800 menu-state-primary menu-arrow-gray-800 fw-semibold fs-6 my-5 my-lg-0'>
                                            <div className='h-20px menu-item d-flex align-items-center justify-content-between'>
                                                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Chi nhánh</span>
                                                <Link to="/stores">
                                                    <button id="TooltipAddStore" className="btn btn-icon btn-color-gray-700 btn-active-color-primary justify-content-end">
                                                        <span className="svg-icon svg-icon-1 svg-icon-gray-300 me-n1">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor" />
                                                                <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor" />
                                                                <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor" />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                    <UncontrolledTooltip
                                                        placement="right"
                                                        target="TooltipAddStore"
                                                    >
                                                        Thêm chi nhánh
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </div>
                                            <div className='my-2'>
                                                {
                                                    wh.map((value, index) => {
                                                        return (
                                                            <div className="menu-item" key={index}>
                                                                <span className={parseInt(localStorage.getItem("StoreId")) === value.Id ? "menu-link active" : "menu-link"} onClick={() => setStoreId(value)} key={index}>
                                                                    <span className="menu-icon">
                                                                        <span className="svg-icon svg-icon-2">
                                                                            {
                                                                                value.FirstStore ?
                                                                                    <svg className='mx-1' width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M13.0079 2.6L15.7079 7.2L21.0079 8.4C21.9079 8.6 22.3079 9.7 21.7079 10.4L18.1079 14.4L18.6079 19.8C18.7079 20.7 17.7079 21.4 16.9079 21L12.0079 18.8L7.10785 21C6.20785 21.4 5.30786 20.7 5.40786 19.8L5.90786 14.4L2.30785 10.4C1.70785 9.7 2.00786 8.6 3.00786 8.4L8.30785 7.2L11.0079 2.6C11.3079 1.8 12.5079 1.8 13.0079 2.6Z" fill="currentColor" />
                                                                                    </svg> :
                                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path opacity="0.3" d="M19 18C20.7 18 22 16.7 22 15C22 13.3 20.7 12 19 12C18.9 12 18.9 12 18.8 12C18.9 11.7 19 11.3 19 11C19 9.3 17.7 8 16 8C15.4 8 14.8 8.2 14.3 8.5C13.4 7 11.8 6 10 6C7.2 6 5 8.2 5 11C5 11.3 5.00001 11.7 5.10001 12H5C3.3 12 2 13.3 2 15C2 16.7 3.3 18 5 18H19Z" fill="currentColor" />
                                                                                    </svg>
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                    <span className="menu-title">
                                                                        {value.Name}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </motion.div>
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}