import React from 'react';

const Home = React.lazy(() => import("./components/Home"));
const Staff = React.lazy(() => import("./components/Staff/Staff"));
const Group = React.lazy(() => import("./components/Group/Group"));
const StoreSelect = React.lazy(() => import("./components/StoreSelect"));
const Profile = React.lazy(() => import("./components/Profile/Profile"));
const GeneralSetting = React.lazy(() => import("./components/Settings/GeneralSetting"));
const RoleSetting = React.lazy(() => import("./components/Settings/RoleSetting"));
const Audit = React.lazy(() => import("./components/Settings/Audit"));

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/stores',
    element: <StoreSelect />
  },
  {
    path: '/staff',
    element: <Staff />
  },
  {
    path: '/groups',
    element: <Group />
  },
  // Profile
  {
    path: '/profile',
    element: <Profile/>
  },
  // Settings
  {
    path: '/settings',
    element: <GeneralSetting />
  },
  {
    path: '/role',
    element: <RoleSetting />
  },
  // History
  {
    path: '/history',
    element: <Audit />
  },
];

export default AppRoutes;
