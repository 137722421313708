import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import DataSource from '../helpers/DataSource';
import { toast } from 'react-toastify';
import Logout from '../helpers/Logout';

const StoreSelect = () => {

    const [data, setData] = useState([]);
    const [numOfStore, setNumOfStore] = useState(0);
    const [openAddStore, setOpenAddStore] = useState(false);
    const [model, setModel] = useState({ Name: '' });

    const plan = localStorage.getItem("Plan");

    useEffect(() => {
        DataSource(`odata/Stores?$orderby=Id%20asc&$count=true`).then(res => {
            setData(res.data.value);
            setNumOfStore(res.data['@odata.count']);
        });
    }, []);

    const toggleAdddata = () => {
        setOpenAddStore(prevState => !prevState);
        setModel({ Name: '' })
    }

    const handleChangedata = (e) => {
        setModel(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const handleSubmitdata = (e) => {
        e.preventDefault();
        if (model.Name === null || model.Name.trim().length === 0) {
            toast.warning("Yêu cầu nhập tên cửa hàng!", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                theme: localStorage.getItem("Theme"),
            });
            return false;
        }
        DataSource(`odata/Stores`, 'POST', model).then(res => {
            if (res.status === 200) {
                toast.success('✌ Đã thêm cửa hàng mới vào hệ thống!', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    theme: localStorage.getItem("Theme"),
                });
                toggleAdddata();
                DataSource(`odata/Stores?$count=true`).then(res => {
                    setData(res.data.value);
                    setNumOfStore(res.data['@odata.count']);
                });
            }
        });
    }

    const handleSetStoreId = (value) => {
        localStorage.setItem("StoreId", value.Id);
        localStorage.setItem("StoreName", value.Name);
        localStorage.setItem("StoreCreated", value.CreatedDate);
        localStorage.setItem("StoreExpiry", value.ExpiryDate);
        localStorage.setItem("FirstStore", value.FirstStore);
        window.location.href = '/';
    }

    const canAddMore = () => {
        if ((plan == "Free" || plan == "Startup") && numOfStore >= 1) {
            return false;
        } else if (plan == "Plus" && numOfStore >= 3) {
            return false;
        }
        return true;
    }

    return (
        <>
            <div className="d-flex flex-column flex-root">
                <div className="d-flex flex-column flex-column-fluid">
                    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-10">
                        <img alt="Logo" src="./img/logo.png" width={110} className="mx-auto" />
                        <div className="pt-lg-10 mb-10">
                            <div className="store-list">
                                {
                                    data.map((value, index) => (
                                        <Button onClick={() => handleSetStoreId(value)} className="box-store" value={value.Id} key={index}>
                                            <span className='text-gray m-0'>
                                                {
                                                    value.FirstStore &&
                                                    <svg className='me-1' width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.0079 2.6L15.7079 7.2L21.0079 8.4C21.9079 8.6 22.3079 9.7 21.7079 10.4L18.1079 14.4L18.6079 19.8C18.7079 20.7 17.7079 21.4 16.9079 21L12.0079 18.8L7.10785 21C6.20785 21.4 5.30786 20.7 5.40786 19.8L5.90786 14.4L2.30785 10.4C1.70785 9.7 2.00786 8.6 3.00786 8.4L8.30785 7.2L11.0079 2.6C11.3079 1.8 12.5079 1.8 13.0079 2.6Z" fill="currentColor" />
                                                    </svg>
                                                }
                                                {value.Name}
                                            </span>
                                        </Button>
                                    ))
                                }
                                {
                                    canAddMore() && (
                                        <Button className='box-store btn-addStore' onClick={toggleAdddata}>
                                            <svg width="42" height="42" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor" />
                                                <path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z" fill="currentColor" />
                                                <path opacity="0.3" d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z" fill="currentColor" />
                                            </svg>
                                            Tạo chi nhánh
                                        </Button>
                                    )
                                }
                            </div>
                            <div className="text-center mt-10">
                                <Button onClick={Logout} className="btn btn-lg btn-primary fw-bold">Log out</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={openAddStore}>
                <Form onSubmit={handleSubmitdata}>
                    <ModalHeader>Thêm chi nhánh mới</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>
                                Tên chi nhánh
                            </Label>
                            <Input
                                name="Name"
                                placeholder="Vd: Tên chi nhánh + tên đường,..."
                                type="text"
                                value={model.Name}
                                onChange={handleChangedata}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' type='submit'>
                            Thêm chi nhánh
                        </Button>{' '}
                        <Button color='secondary' onClick={toggleAdddata}>
                            Bỏ qua
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

export default StoreSelect;