export default function Logout() {
    localStorage.removeItem("StoreId");
    localStorage.removeItem("StoreName");
    localStorage.removeItem("StoreExpiry");
    localStorage.removeItem("FirstStore");
    localStorage.removeItem("Email");
    localStorage.removeItem("Token");
    localStorage.removeItem("Expiration");
    localStorage.removeItem("TenantId");
    localStorage.removeItem("Roles");
    localStorage.removeItem("Permissions");
    localStorage.removeItem("Type");
    localStorage.removeItem("Plan");
    localStorage.removeItem("PlanExpiry");
    localStorage.removeItem("SettingWH");
    localStorage.removeItem("Biz");
    localStorage.removeItem("Fullname");
    localStorage.removeItem("StoreCreated");
    localStorage.removeItem("Period");
    window.location.replace("/")
}