import { Component } from "react";

export default class PageLoading extends Component {
    render() {
        return (
            <div className="page-loader flex-column kt-page-bg bg-opacity-90"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <span className="spinner-border text-primary" role="status"></span>
                <span className="text-gray-800 fs-6 fw-semibold mt-5">Loading...</span>
            </div>
        );
    }
}