import React, { useEffect, useState, Suspense } from 'react';
import Aside from './Common/Aside';
import PageLoading from '../helpers/PageLoading';
import Swal from 'sweetalert2';

export default function Layout(props) {

  const [close, setClose] = useState(false);

  useEffect(() => {
    const loadingEl = document.createElement("div");
    loadingEl.classList.add("page-loader");
    loadingEl.classList.add("flex-column");
    loadingEl.classList.add("bg-black");
    loadingEl.classList.add("bg-opacity-50");
    loadingEl.innerHTML = `
          <span class="spinner-border text-primary" role="status"></span>
          <span class="text-gray-800 fs-6 fw-semibold mt-5">Loading...</span>
      `;
    document.getElementById('content').appendChild(loadingEl);
    if (window.screen.width < 900) {
      const $style = document.createElement("style");
      document.head.appendChild($style);
      $style.innerHTML = `.rdt_Table, .table-responsive { width: ${window.screen.width - 60}px !important;}`;

      document.querySelector('.aside').classList.toggle('drawer');
      document.querySelector('.aside').classList.toggle('drawer-start');
    }
    
    const profileMenu = document.querySelector('.account-menu-item');
    profileMenu.addEventListener("click", () => {
        profileMenu.classList.toggle('show');
    });
  }, [])

  const toggleAside = () => {
    setClose(prevCheck => !prevCheck);
  }

  const enableAside = () => {
    function toggleDrawer() {
      document.querySelector('.aside').classList.toggle('drawer-on');
    }
    toggleDrawer();
    const overlay = document.createElement("div");
    overlay.classList.add("drawer-overlay");
    overlay.addEventListener("click", function () {
      toggleDrawer();
      document.body.removeChild(overlay);
    });
    document.body.appendChild(overlay);
  }

  return (
    <div className='d-flex flex-column flex-root top-layout' data-kt-aside-minimize={close}>
      <div className='page d-flex flex-row flex-column-fluid'>
        <Aside toggleAside={toggleAside} isClose={close} />
        <div className='wrapper d-flex flex-column flex-row-fluid'>
          <div id="kt_header" className="header d-sm-flex d-lg-none">
            <div className="container-xxl d-flex align-items-center justify-content-between">
              <div className="d-flex d-lg-none align-items-center ms-n2 me-2">
                <div className="btn btn-icon btn-active-icon-primary" id="kt_aside_toggle" onClick={enableAside}>
                  <span className="svg-icon svg-icon-2x"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor"></path>
                    <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor"></path>
                  </svg>
                  </span>
                </div>
                <a href="#" className="d-flex align-items-center">
                  <img alt="Logo" src="./img/logo.svg" className="h-40px" />
                </a>
              </div>
            </div>
          </div>
          <div id="content" className='content d-flex flex-column flex-column-fluid' style={{ position: 'relative' }}>
            <Suspense fallback={<PageLoading />}>
              <div className='container-xxl flex-column-fluid'>
                {props.children}
              </div>
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
}
