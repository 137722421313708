import React, { useEffect, useState } from 'react';
import { Input, Form, Label, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import DataSource, { API_URL } from './helpers/DataSource';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import GetParam from './helpers/GetParam';
import { motion, AnimatePresence } from "framer-motion";

export default function Login() {

    const [scene, setScene] = useState('login');
    const [registerModel, setRegisterModel] = useState({ Biz: 'Retail' });
    const [loginModel, setLoginModel] = useState({ Email: '', Password: '' });
    const [resetPwdModel, setResetPwdModel] = useState({ Token: '', NewPassword: '' });
    const [Iagree, setIagree] = useState(false);
    const [disabledLogin, setDisabledLogin] = useState(false);
    const [disabledRegister, setDisabledRegister] = useState(false);
    const [disableReset, setDisabledReset] = useState(false);
    const [disableSendEmailReset, setDisableSendEmailReset] = useState(false);
    const [score, setScore] = useState(0);
    const [checkSteps, setCheckSteps] = useState(5);
    const [openPolicy, setOpenPolicy] = useState(false);
    const [resetEmail, setResetEmail] = useState('');

    // PasswordMeter
    const defaultOptions = {
        minLength: 8,
        checkUppercase: true,
        checkLowercase: true,
        checkDigit: true,
        checkChar: true,
        scoreHighlightClass: 'active'
    }

    useEffect(() => {
        if (GetParam(1) !== "") {
            setResetPwdModel(prevState => ({ ...prevState, Token: GetParam(2) }));
        }

        localStorage.removeItem("StoreId");
        localStorage.removeItem("StoreName");
        localStorage.removeItem("StoreExpiry");
        localStorage.removeItem("FirstStore");
        localStorage.removeItem("Email");
        localStorage.removeItem("Token");
        localStorage.removeItem("Expiration");
        localStorage.removeItem("TenantId");
        localStorage.removeItem("Roles");
        localStorage.removeItem("Permissions");
        localStorage.removeItem("Type");
        localStorage.removeItem("Plan");
        localStorage.removeItem("PlanExpiry");
        localStorage.removeItem("SettingWH");
        localStorage.removeItem("Biz");
        localStorage.removeItem("Fullname");
        localStorage.removeItem("StoreCreated");
        localStorage.removeItem("Period");
    }, []);


    const _check = (e) => {
        var score = 0;
        var checkScore = _getCheckScore(e);

        if (_checkLength(e) === true) {
            score = score + checkScore;
        }

        if (defaultOptions.checkLowercase === true && _checkLowercase(e) === true) {
            score = score + checkScore;
        }

        if (defaultOptions.checkLowercase === true && _checkUppercase(e) === true) {
            score = score + checkScore;
        }

        if (defaultOptions.checkDigit === true && _checkDigit(e) === true) {
            score = score + checkScore;
        }

        if (defaultOptions.checkChar === true && _checkChar(e) === true) {
            score = score + checkScore;
        }

        setScore(score);
        _highlight(e);
    }


    const _getCheckScore = (e) => {
        var count = 1;

        if (defaultOptions.checkUppercase === true) {
            count++;
        }

        if (defaultOptions.checkLowercase === true) {
            count++;
        }

        if (defaultOptions.checkDigit === true) {
            count++;
        }

        if (defaultOptions.checkChar === true) {
            count++;
        }

        setCheckSteps(count);
        return (100 / count);
    }

    const _checkLength = (e) => {
        return e.target.value.length >= defaultOptions.minLength; // 20 score
    }

    const _checkLowercase = (e) => {
        return /[a-z]/.test(e.target.value); // 20 score
    }

    const _checkUppercase = (e) => {
        return /[A-Z]/.test(e.target.value); // 20 score 
    }

    const _checkDigit = (e) => {
        return /[0-9]/.test(e.target.value); // 20 score
    }

    const _checkChar = (e) => {
        return /[~`!#@$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(e.target.value); // 20 score
    }

    const _highlight = (e) => {
        var items = [].slice.call(document.getElementById("highlight").getElementsByTagName('div'));
        var total = items.length;
        var index = 0;
        var checkScore = _getCheckScore(e);
        var score = _getScore();

        items.map((item) => {
            index++;
            if ((checkScore * index * (checkSteps / total)) <= score) {
                item.classList.add(defaultOptions.scoreHighlightClass);
            } else {
                item.classList.remove(defaultOptions.scoreHighlightClass);
            }
        });
    }

    // Gets current password score
    const _getScore = () => {
        return score;
    }

    const handleChangeLoginModel = (e) => setLoginModel(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    const handleChangeRegisterModel = (e, pwm) => {
        setRegisterModel(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        if (pwm === true) {
            _check(e);
        }
    }

    const handleCheckAgree = (e) => setIagree(prevState => !prevState);
    const handleOpenPolicy = () => setOpenPolicy(prevState => !prevState);

    const reset = (e) => {
        e.preventDefault();
        if (disableReset) {
            return;
        }
        setDisabledReset(true);
        DataSource(`api/authenticate/reset`, 'POST', resetPwdModel)
            .catch(error => {
                toast.error(error.response.data.Message, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    theme: localStorage.getItem("Theme"),
                });
                Object.keys(error.response.data.errors).map((value, index) => {
                    toast.error(error.response.data.errors[value][0], {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        theme: localStorage.getItem("Theme"),
                    });
                });
                setDisabledReset(false);
                return false;
            })
            .then(res => {
                if (res.status === 200) {
                    setScene('login');
                    toast.success("Đã reset password thành công, quý khách hãy thử đăng nhập lại", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        theme: localStorage.getItem("Theme"),
                    });
                    window.location.href = '/';
                }
                setDisabledReset(false);
            });
    }

    const sendResetEmail = (e) => {
        e.preventDefault();
        if (resetEmail.length < 8) {
            return;
        }
        setDisableSendEmailReset(true);
        DataSource(`api/authenticate/forgot`, 'POST', { Email: resetEmail })
            .catch(error => {
                toast.error(error.response.data.Message, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    theme: localStorage.getItem("Theme"),
                });
                Object.keys(error.response.data.errors).map((value, index) => {
                    toast.error(error.response.data.errors[value][0], {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        theme: localStorage.getItem("Theme"),
                    });
                });
                return false;
            })
            .then(res => {
                if (res.status === 200) {
                    setScene('login');
                    Swal.fire({
                        title: 'Đã gửi email khôi phục password',
                        text: 'Chúng tôi đã gửi 1 email tới quý khách, hãy vào đường link được cung cấp để tạo mật khẩu mới',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = '/';
                        }
                    });
                }
                setDisableSendEmailReset(false);
            });

    }

    const register = (e) => {
        e.preventDefault();
        if (disabledRegister || score < 100) {
            return;
        }

        if (registerModel.Password !== registerModel.ConfirmPassword) {
            toast.error("Mật khẩu quý khách nhập không khớp nhau!", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                theme: localStorage.getItem("Theme"),
            });
            setDisabledRegister(false);
            return false;
        }

        setDisabledRegister(true);

        if (registerModel.FullName === undefined || registerModel.Email === undefined || registerModel.Password === undefined) {
            toast.error("Yêu cầu nhập đầy đủ họ tên, tên đăng nhập (là email) và mật khẩu của quý khách", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                theme: localStorage.getItem("Theme"),
            });
            setDisabledRegister(false);
            return false;
        }

        DataSource(`api/authenticate/register`, 'POST', registerModel)
            .catch(error => {
                toast.error(error.response.data.Message, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    theme: localStorage.getItem("Theme"),
                });
                Object.keys(error.response.data.errors).map((value, index) => {
                    toast.error(error.response.data.errors[value][0], {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        theme: localStorage.getItem("Theme"),
                    });
                });
                setDisabledRegister(false);
                return false;
            })
            .then(res => {
                if (res.status === 200) {
                    setScene('login');
                    toast.success("Đã tạo tài khoản thành công, quý khách có thể sử dụng thông tin vừa tạo đăng nhập vào hệ thống", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        theme: localStorage.getItem("Theme"),
                    });
                }
                setDisabledRegister(false);
            });
    }

    const saveLocal = (res) => {
        localStorage.setItem("Fullname", res.data.fullname);
        localStorage.setItem("Email", res.data.email);
        localStorage.setItem("Token", res.data.token);
        localStorage.setItem("TenantId", res.data.tenant);
        localStorage.setItem("Expiration", res.data.expiration);
        localStorage.setItem("Roles", res.data.roles);
        localStorage.setItem("Permissions", res.data.permissions);
        localStorage.setItem("Type", res.data.type);
        localStorage.setItem("Plan", res.data.plan);
        localStorage.setItem("PlanExpiry", res.data.plan_expiry);
        localStorage.setItem("Biz", res.data.biz);
        localStorage.setItem("StoreId", res.data.storeId);
        localStorage.setItem("StoreName", res.data.storeName);
        localStorage.setItem("StoreExpiry", res.data.storeExpiry);

        if(res.data.type === 'Cashier')
            window.location.replace('/pos');
        else
            window.location.replace('/');
    }

    const login = (e) => {
        e.preventDefault();
        if (disabledLogin) {
            return;
        }
        setDisabledLogin(true);

        if (Object.keys(loginModel).length === 0 || loginModel.Email === '' || loginModel.Password === '') {
            toast.error("Yêu cầu nhập đầy đủ tên đăng nhập (là email) và mật khẩu của quý khách", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                theme: localStorage.getItem("Theme"),
            });
            setDisabledLogin(false);
        } else {
            DataSource(`api/authenticate/login`, 'POST', loginModel)
                .then(res => {
                    if (res.status === 200) {
                        if (res.data.emailConfirmed === false) {
                            var token = res.data.token;
                            Swal.fire({
                                title: 'Nhập code kích hoạt tài khoản được gửi qua email mà quý khách đăng ký',
                                input: 'text',
                                inputAttributes: {
                                    autocapitalize: 'off'
                                },
                                showCancelButton: true,
                                confirmButtonText: 'Kích hoạt',
                                cancelButtonText: 'Bỏ qua',
                                showLoaderOnConfirm: true,
                                preConfirm: (code) => {
                                    fetch(API_URL + `api/authenticate/active`, {
                                        method: 'POST',
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            Token: token,
                                            Code: code
                                        })
                                    }).then((res) => {
                                        return res.json();
                                    }).then((data) => {
                                        if (data.Status === "Ok") {
                                            toast.success(data.Message, {
                                                position: "bottom-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                theme: localStorage.getItem("Theme"),
                                            });
                                            saveLocal(res);
                                        } else {
                                            toast.warning(data.Message, {
                                                position: "bottom-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                theme: localStorage.getItem("Theme"),
                                            });
                                        }
                                    })
                                },
                                allowOutsideClick: () => !Swal.isLoading()
                            });
                        } else {
                            if (res.data?.locked === true) {
                                Swal.fire({
                                    title: 'Ooppss!!!',
                                    text: "Tài khoản của quý khách đã bị tạm khóa",
                                    icon: 'warning'
                                });
                                setDisabledLogin(false);
                            } else {
                                saveLocal(res);
                            }
                        }
                    } else {
                        setDisabledLogin(false);
                    }
                    setDisabledLogin(false);
                });
            setDisabledLogin(false);
        }
    }

    const handleBizChange = (e) => {
        setRegisterModel(prevState => ({ ...prevState, [e.target.name]: parseInt(e.target.value) }));
    }


    return (
        <>
            <div className='w-100 d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed auth-page-bg'>
                <div className='d-flex flex-center flex-column flex-column-fluid px-5 py-10 pb-lg-20'>
                    <a href="/" className="mb-12 text-center">
                        <img alt="Logo" src="./img/logo.png" className="h-100px mt-10 mb-5" />
                    </a>
                    <AnimatePresence>
                        {
                            resetPwdModel.Token !== '' && GetParam(1) === 'reset' ?
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    <div className="w-100 w-100 w-lg-550px bg-body rounded shadow-sm p-5 py-10 p-lg-15 mx-auto">
                                        <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={reset}>
                                            <div className="text-center mb-10">
                                                <h1 className="text-dark mb-3">
                                                    Reset password
                                                </h1>
                                            </div>

                                            <div className="mb-10 fv-row fv-plugins-icon-container" data-kt-password-meter="true">
                                                <div className="mb-1">
                                                    <label className="form-label fs-5  fs-5 fw-bold text-dark ">
                                                        Nhập password mới
                                                    </label>
                                                    <Input className="form-control form-control-lg border-danger" type="password" placeholder="" name="Password" autoComplete="off"
                                                        onChange={(e) => setResetPwdModel(prevState => ({ ...prevState, NewPassword: e.target.value }))} />
                                                    <div className="text-danger fs-7 mt-1">Password từ 8 kí tự, bao gồm kí tự viết thường, viết hoa và kí tự đặc biệt</div>
                                                </div>
                                            </div>

                                            <div className="text-center">
                                                <button type="submit" className="btn btn-lg btn-primary w-100 mb-5"
                                                    disabled={
                                                        resetPwdModel.NewPassword?.length < 8
                                                    }
                                                    data-kt-indicator={disableReset}>
                                                    <span className="indicator-label">
                                                        {disableReset ? 'Vui lòng chờ...' : 'Reset'}
                                                    </span>
                                                    <span className="indicator-progress">
                                                        Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </motion.div>
                                :
                                scene === 'login'
                                    ? (
                                        <motion.div
                                            className='w-100'
                                            initial={{ y: 15, opacity: 0 }}
                                            animate={{ y: 0, opacity: 1 }}
                                            exit={{ y: -15, opacity: 0 }}
                                            transition={{ duration: 0.5 }}>
                                            <div className="w-100 w-md-500px bg-body rounded shadow-sm px-5 py-10 p-lg-15 mx-auto">
                                                <Form className="form w-100" onSubmit={login}>
                                                    <div className="text-center mb-10">
                                                        <h1 className="text-dark mb-3">
                                                            Đăng nhập
                                                        </h1>

                                                        <div className="text-gray-400 fs-5 fw-semibold ">
                                                            Hoặc
                                                            {' '}
                                                            <span style={{ cursor: 'pointer' }} className="link-success fw-bold" onClick={() => setScene('register')}>
                                                                Đăng ký miễn phí
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="fv-row mb-10">
                                                        <label className="form-label fs-5 fw-bold text-dark">Email</label>
                                                        <Input className="form-control form-control-lg " type="text" name="Email" autoComplete="off" onChange={handleChangeLoginModel} />
                                                    </div>

                                                    <div className="fv-row mb-10">
                                                        <div className="d-flex flex-stack mb-2">
                                                            <label className="form-label fs-5 fw-bold text-dark mb-0">Password</label>
                                                            <span className="text-muted text-hover-primary fw-bold cursor-pointer" onClick={() => setScene('forgot')}>
                                                                Quên mật khẩu?
                                                            </span>
                                                        </div>
                                                        <Input className="form-control form-control-lg " type="password" name="Password" autoComplete="off" onChange={handleChangeLoginModel} />
                                                    </div>

                                                    <div className="text-center">
                                                        <button type="submit" className="btn btn-lg btn-primary w-100 mb-5"
                                                            disabled={
                                                                loginModel.Email === ''
                                                                || loginModel.Password === ''
                                                                || loginModel.Password?.length < 8
                                                            }
                                                            data-kt-indicator={disabledLogin}>
                                                            <span className="indicator-label">
                                                                {disabledLogin ? 'Vui lòng chờ...' : 'Login'}
                                                            </span>
                                                            <span className="indicator-progress">
                                                                Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </motion.div>
                                    )
                                    :
                                    scene === 'register' ?
                                        (
                                            <motion.div
                                                initial={{ y: -15, opacity: 0 }}
                                                animate={scene === 'register' ? { opacity: 1 } : { opacity: 0 }}
                                                exit={{ y: 15, opacity: 0 }}
                                                transition={{ duration: 0.5 }}>
                                                <div className="w-100 w-lg-500px bg-body rounded shadow-sm p-5 py-10 p-lg-15 mx-auto">
                                                    <Form className="form w-100" onSubmit={register}>
                                                        <div className="mb-10 text-center">
                                                            <h1 className="text-dark mb-3">
                                                                Đăng ký tài khoản
                                                            </h1>

                                                            <div className="text-gray-400 fw-semibold fs-5">
                                                                Hoặc
                                                                {' '}
                                                                <span style={{ cursor: 'pointer' }} className="link-success fw-bold" onClick={() => setScene('login')}>
                                                                    Login tại đây
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <input type="hidden"></input>
                                                        <input type="hidden"></input>
                                                        <div className="fv-row mb-10">
                                                            <label className="form-label fs-5 fw-bold text-dark required">Họ tên</label>
                                                            <Input className="form-control form-control-lg " type="text" placeholder="" name="FullName" autoComplete="off" onChange={handleChangeRegisterModel} />
                                                        </div>

                                                        <div className="fv-row mb-10">
                                                            <label className="form-label fs-5 fw-bold text-dark required">Email</label>
                                                            <Input className="form-control form-control-lg " type="email" placeholder="" name="Email" autoComplete="off" onChange={handleChangeRegisterModel} />
                                                            <div className="text-muted fs-7 mt-1">Chúng tôi sẽ gửi email kích hoạt tài khoản qua email này</div>
                                                        </div>

                                                        <div className="fv-row mb-10">
                                                            <label className="form-label fs-5 fw-bold text-dark required">
                                                                Password
                                                            </label>
                                                            <Input className="form-control form-control-lg border-danger" type="password" placeholder="" name="Password" autoComplete="off" onChange={(e) => handleChangeRegisterModel(e, true)} />
                                                            <div className="text-danger fs-7 mt-1">Password từ 8 kí tự, bao gồm kí tự viết thường, viết hoa và kí tự đặc biệt</div>
                                                            <div className="d-flex align-items-center my-3" id="highlight">
                                                                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                                                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                                                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                                                                <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                                                            </div>
                                                        </div>
                                                        <div className="fv-row mb-10">
                                                            <label className="form-label fs-5 fw-bold text-dark required">
                                                                Confirm Password
                                                            </label>
                                                            <Input className="form-control form-control-lg " type="password" placeholder="" name="ConfirmPassword" autoComplete="off" onChange={handleChangeRegisterModel} />
                                                        </div>
                                                        <div className="fv-row mb-10">
                                                            <Label className="form-label fs-5 fw-bold text-gray mb-5 required">Chọn loại hình mô tả phù hợp nhất với quý khách:</Label>
                                                            <FormGroup>
                                                                <input type="radio" className="btn-check" name="Biz" value="Retail"
                                                                    id="BizType_Retail"
                                                                    defaultChecked
                                                                    onChange={handleBizChange}></input>
                                                                <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4" htmlFor="BizType_Retail">
                                                                    <span className="fw-semibold">Bán hàng sỉ & lẻ</span>
                                                                </label>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <input type="radio" className="btn-check" name="Biz" value="Restaurant"
                                                                    id="BizType_Restaurant"
                                                                    onChange={handleBizChange}></input>
                                                                <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4" htmlFor="BizType_Restaurant">
                                                                    <span className="fw-semibold">Bar - Cafe, Nhà hàng</span>
                                                                </label>
                                                            </FormGroup>
                                                            {/*<FormGroup>
                                                            <input type="radio" className="btn-check" name="Biz" value="TimeService"
                                                                id="BizType_Service"
                                                                onChange={handleBizChange}></input>
                                                            <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4" htmlFor="BizType_Service">
                                                                <span className="fw-semibold">Dịch vụ tính giờ</span>
                                                            </label>
                                                        </FormGroup>*/}
                                                        </div>
                                                        <div className="fv-row mb-10">
                                                            <label className="form-label fs-5 fw-bold text-dark ">Mã giới thiệu</label>
                                                            <Input className="form-control form-control-lg" type="text" placeholder="" name="Ref" autoComplete="off" onChange={handleChangeRegisterModel} />
                                                        </div>
                                                        <div className="fv-row mb-10">
                                                            <label className="form-check form-check-custom form-check-solid form-check-inline">
                                                                <input className="form-check-input" type="checkbox" name="toc" value="1" onChange={handleCheckAgree} />
                                                                <span className="form-check-label fw-semibold text-gray-700 ">
                                                                    Đồng ý với <span className="ms-1 link-primary cursor-pointer" onClick={handleOpenPolicy}>chính sách bảo mật thông tin</span>.
                                                                </span>
                                                            </label>
                                                        </div>

                                                        <div className="text-center">
                                                            <button type="submit" className="btn btn-lg btn-primary w-100 mb-5"
                                                                disabled={
                                                                    score < 100
                                                                    || registerModel.FullName === ''
                                                                    || registerModel.Email === ''
                                                                    || registerModel.Password === ''
                                                                    || registerModel.ConfirmPassword === ''
                                                                    || registerModel.Email === ''
                                                                    || Iagree === false
                                                                }
                                                                data-kt-indicator={disabledRegister}>
                                                                <span className="indicator-label">
                                                                    {disabledRegister ? 'Vui lòng chờ...' : 'Đăng ký'}
                                                                </span>
                                                                <span className="indicator-progress">
                                                                    Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </motion.div>
                                        )
                                        :
                                        <motion.div
                                            initial={{ y: -15, opacity: 0 }}
                                            animate={scene === 'forgot' ? { opacity: 1 } : { opacity: 0 }}
                                            exit={{ y: 15, opacity: 1 }}
                                            transition={{ duration: 0.5 }}>
                                            <div className="w-100 w-lg-500px bg-body rounded shadow-sm p-5 py-10 p-lg-15 mx-auto">
                                                <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={sendResetEmail}>
                                                    <div className="text-center mb-10">
                                                        <h1 className="text-dark mb-3">
                                                            Quên mật khẩu ?
                                                        </h1>

                                                        <div className="fs-5 text-gray-400 fw-semibold ">
                                                            Nhập email của bạn
                                                        </div>
                                                    </div>

                                                    <div className="fv-row mb-10 fv-plugins-icon-container">
                                                        <label className="form-label fs-5 fw-bold text-gray-900 ">Email</label>
                                                        <input className="form-control form-control-solid" type="email" placeholder="" name="email" autoComplete="off"
                                                            onChange={(e) => setResetEmail(e.target.value)} />
                                                    </div>

                                                    <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                                                        <button type="submit" className="btn btn-lg btn-primary w-100 mb-5"
                                                            disabled={
                                                                disableSendEmailReset === true
                                                                || resetEmail.length < 8
                                                            }
                                                            data-kt-indicator={disableSendEmailReset}>
                                                            <span className="indicator-label">
                                                                {disableSendEmailReset ? 'Vui lòng chờ...' : 'Submit'}
                                                            </span>
                                                            <span className="indicator-progress">
                                                                Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        </button>

                                                        <button onClick={() => setScene('login')} className="btn btn-lg btn-link-primary fw-bold">Quay lại</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </motion.div>
                        }
                    </AnimatePresence>
                </div>
            </div>
            <Modal isOpen={openPolicy} toggle={handleOpenPolicy}>
                <ModalHeader toggle={handleOpenPolicy}>Chính sách bảo mật</ModalHeader>
                <ModalBody>

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleOpenPolicy}>
                        Đóng
                    </Button>{' '}
                </ModalFooter>
            </Modal>
        </>
    );
}