import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/Layout';
import Login from './Login';
import StoreSelect from './components/StoreSelect';
import DiffDates from './helpers/DiffDates';
import Logout from './helpers/Logout';
import DataSource from './helpers/DataSource';
import Swal from 'sweetalert2';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import '@availity/block-ui/dist/index.css';
import './custom.css';
import GetParam from './helpers/GetParam';

export default function App() {
  const navigate = useNavigate();

  const checkOS = () => {
    var platform = navigator.platform;

    if (/Win/i.test(platform)) {
      return "Windows";
    } else if (/Mac/i.test(platform)) {
      return "MacOS";
    } else if (/Linux/i.test(platform)) {
      return "Linux";
    } else if (/iPhone|iPad|iPod/i.test(platform)) {
      return "iOS";
    } else if (/Android/i.test(platform)) {
      return "Android";
    } else {
      return "Unknown";
    }
  }

  const checkStandalone = () => {
    function checkAndroidStandalone() {
      if ('standalone' in window.navigator && window.navigator.standalone) {
        return true;
      }

      if (window.matchMedia('(display-mode: standalone)').matches) {
        return true;
      }

      return false;
    }
  }

  window.onload = function () {
    if (localStorage.getItem("Theme") === null) {
      localStorage.setItem("Theme", "dark");
    }

    if (localStorage.getItem("Theme") === "dark") {
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      document.documentElement.setAttribute("data-theme", "light");
    }

    if (localStorage.getItem("Bg") !== null) {
      document.documentElement.setAttribute("data-bg", localStorage.getItem("Bg"));
    }

  }

  if (localStorage.getItem("Token") !== null) {


    if(localStorage.getItem("Type") === 'Cashier')
    {
      if (GetParam(1) !== "pos") {
        window.location.replace('/pos');
      }
    }
      
    if (DiffDates(new Date(), new Date(localStorage.getItem("Expiration"))).days <= 0) {
      Swal.fire({
        title: 'Bạn cần đăng nhập lại',
        text: 'Mỗi lần đăng nhập hệ thống chỉ giữ tình trạng đăng nhập của bạn trong 7 ngày. Posify cần bạn login lại để đảm bảo tính an toàn của hệ thống',
        icon: 'info',
        showCancelButton: false,
        confirmButtonColor: '#7066e0',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Đồng ý',
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          Logout();
        }
      });
    }

    DataSource("json/checktoken", "GET").then(res => {
      if (res.data === false) {
        Swal.fire({
          title: 'Thiết bị khác đăng nhập',
          text: 'Tài khoản của quý khách đã đăng nhập trên một thiết bị khác, vui lòng đăng nhập lại',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#7066e0',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Đồng ý',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            Logout();
          }
        });
      }
    });

    if (localStorage.getItem("StoreId") !== null) {
    
    }

  }

  return (
    <>
      {
        localStorage.getItem("Token") === null ? <Login /> :
          localStorage.getItem("StoreId") === null || localStorage.getItem("StoreId") === 'null' ? <StoreSelect /> :
            <Layout>
              <Routes>
                {AppRoutes.map((route, index) => {
                  const { element, ...rest } = route;
                  return <Route key={index} {...rest} element={element} />;
                })}
              </Routes>
            </Layout>
      }
      {
        (checkOS() === 'iOS' && checkStandalone() === false) && (
          <div className="box arrow-bottom">
            Để cài đặt Posify về điện thoại, bạn chọn vào <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="currentColor" />
              <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="currentColor" />
              <path opacity="0.3" d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="currentColor" />
            </svg> và chọn Add to Home Screen
          </div>
        )
      }
      <ToastContainer></ToastContainer>
    </>
  );
}
