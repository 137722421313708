export default function Identity (props) {
    const myPermissions = localStorage.getItem("Permissions");
    const myType = localStorage.getItem("Type");

    const Permission = () => {
        if(myType === "Root" || myType === "Cashier")
            return true;
        let arr = props.permissions.split(",");
        let flag = false;
        for(let i in arr) {
            if(myPermissions.indexOf(arr[i]) !== -1) {
                flag = true;
                break;
            }
        }
        if(flag === true) {
            return true;
        }
        return false;
    }

    return(
        <>
        {
            Permission() === true && (props.children)
        }
        </>
    )
}