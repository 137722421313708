import axios from 'axios';
import { toast } from 'react-toastify';
import uuidv4 from './uuidv4';
import checkElapsedTime from './checkElapsedTime';

export const API_URL = process.env.NODE_ENV === 'production' ? 'https://api.bobanbo.vn/' : 'https://localhost:7184/';

export default function DataSource(
    endpoint,
    method = "GET",
    body,
    contentType = "application/json; charset=utf8;",
    safe = false) {

    const StoreId = parseInt(localStorage.getItem("StoreId"));
    const TenantId = localStorage.getItem("TenantId");
    const Token = localStorage.getItem("Token");
    const UUIDv4 = uuidv4();

    if (method === "POST" && body.Id === null) {
        body.Id = -1;
    }

    if (method === "POST" && safe === false) {
        if (sessionStorage.getItem('lastedPostRequest') !== null) {
            if (!checkElapsedTime(sessionStorage.getItem('lastedPostRequest'), 3000)) {
                toast.error("#429 Bạn đang thực hiện gửi request quá nhanh tới máy chủ Posify", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    theme: localStorage.getItem("Theme"),
                });
                return new Promise((resolve) => {
                    resolve({
                        status: 429,
                        statusText: "Too many requests"
                    });
                });
            }
        }
        sessionStorage.setItem('lastedPostRequest', new Date().getTime());
    }

    if (method === "PUT") {
        delete body['@odata.context'];
    }

    return axios({
        headers: {
            'Content-Type': contentType,
            Accept: "application/json",
            TenantId: TenantId,
            StoreId: StoreId,
            Token: Token,
            RequestId: UUIDv4,
        },
        method: method,
        url: `${API_URL}${endpoint}`,
        data: JSON.stringify(body)
    });
}
